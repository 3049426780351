import React from "react";
import nedec_white from "../../assets/images/nedec_logo.png";
import nedec_small from "../../assets/images/nedec_small.png";
import { Link } from "react-scroll";

import HandBugger from "../HandBugger";
import { NavLink } from "react-router-dom";

export default function Header() {
  const ourWork = [
    {
      title: "One Health",
      link: "/our-work/one-health",
      grandchild: [
        { title: "Zoonosis", link: "/our-work/one-health" },
        {
          title: "Antimicrobial resistance (AMR)",
          link: "/our-work/one-health",
        },
        {
          title: "Environmental health & conservation",
          link: "/our-work/one-health",
        },
      ],
    },
    {
      title: "Neglected Tropical Disease (NTDs)",
      link: "/our-work/neglected-tropical",
      grandchild: [],
    },
    {
      title: "Water, Sanitation and Hygiene",
      link: "/our-work/water-sanitation",
      grandchild: [],
    },
    {
      title: "Research and Innovation",
      link: "/our-work/research-innovation",
      grandchild: [],
    },
  ];

  const media = [
    { title: "Gallery", link: "#" },
    { title: "News", link: "#" },
    { title: "Newsletter", link: "#" },
    { title: "Publications", link: "#" },
  ];

  const aboutUs = [
    { title: "Our Mission, Vision & Value", link: "/about-us" },
    { title: "Our Story", link: "/our-story" },
    { title: "Our Expertise", link: "/about-us" },
    { title: "Our Team", link: "/our-team" },
  ];

  return (
    <div id='home'>
      <div className=' bg-[#064663] lg:hidden fixed w-full z-50 h-20'>
        <div className='mx-4 my-2'>
          <div className='flex flex-row justify-between items-center bg-[#064663] bg-opacity-90 md:w-3/4 mx-auto lg:hidden'>
            <NavLink to='/'>
              <img
                src={nedec_white}
                alt='logo'
                className='mx-1 h-7 lg:hidden'
              />
            </NavLink>
            {/* <p></p> */}
            <HandBugger />
          </div>
        </div>
      </div>

      <div className='hidden fixed w-screen mx-auto lg:block bg-[#064663] backdrop-blur-[3px] bg-opacity-90 h-20 z-50'>
        <div className='text-gray-100 mx-[6%] flex flex-row justify-between items-center h-full '>
          <div className='flex flex-col items-center justify-center'>
            <a href='/'>
              <img src={nedec_small} alt='NEDEC' className='h-9' />
            </a>
          </div>
          <div className='flex flex-row gap-0 lg:gap-9 justify-end'>
            <div className=' hover:cursor-pointer flex flex-col items-start justify-center group relative'>
              <a
                href='/about-us'
                className='text-gray-50 group-hover:text-[#D7B95A]'
              >
                About Us
              </a>
              <div className=' h-[0.1rem] hidden mt-10 -ml-3 lg:group-hover:block absolute'>
                <ul className='text-white bg-[#064663] backdrop-blur-[3px] bg-opacity-90 group-hover:w-40 pb-3 group-hover:transition duration-300 ease-out-in '>
                  <div className='h-[0.1rem] w-full bg-[#D7B95A] mb-3'></div>
                  {aboutUs.map((about, id) => {
                    return (
                      <li
                        key={about.link + id}
                        className='py-1 px-4 hover:bg-[#D7B95A] hover:text-[#064663]'
                      >
                        <a href={about.link}>{about.title}</a>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
            <div className=' hover:cursor-pointer flex flex-col items-start justify-center group relative'>
              <a href='#' className='text-gray-50 group-hover:text-[#D7B95A]'>
                Our Work
              </a>
              <div className=' h-[0.1rem] hidden mt-10 -ml-3 lg:group-hover:block absolute w-24'>
                <ul className='text-white bg-[#064663] backdrop-blur-[3px] bg-opacity-90 group-hover:w-72 pb-3 group-hover:transition duration-300 ease-out-in'>
                  <div className='h-[0.1rem] w-full bg-[#D7B95A] mb-3'></div>
                  {ourWork.map((work, id) => {
                    return (
                      <li
                        key={work.link + id}
                        className='py-1 px-4 hover:bg-[#D7B95A] hover:text-[#064663] hover:cursor-pointer'
                      >
                        <a href={work.link}>{work.title}</a>
                        {work.grandchild &&
                          work.grandchild.length > 0 &&
                          work.grandchild.map((child, idx) => {
                            return (
                              <ul className='ml-8' key={child.link + idx}>
                                <li>
                                  <a
                                    href={child.link}
                                    className=' text-white text-left text-sm w-full font-medium hover:text-[#064663]'
                                    // onClick={() => setOpenParent(null)}
                                  >
                                    {child.title + child.link}
                                  </a>
                                </li>
                              </ul>
                            );
                          })}

                        {/* {work.grandchild.length > 0 &&
                          console.log(work.grandchild)} */}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
            <div className=' hover:cursor-pointer flex flex-col items-start justify-center group'>
              <a href='/' className='text-gray-50'>
                Our Impact
              </a>
              <div className='-mt-[0.2rem] w-0 h-[0.1rem] bg-[#D7B95A] lg:group-hover:w-full group-hover:transition duration-300 ease-in-out'></div>
            </div>

            <div className=' hover:cursor-pointer flex flex-col items-start justify-center group relative'>
              <a
                href='learning'
                className='text-gray-50 group-hover:text-[#D7B95A]'
              >
                Media
              </a>
              <div className=' h-[0.1rem] hidden mt-10 -ml-3 lg:group-hover:block absolute w-20'>
                <ul className='text-white bg-[#064663] backdrop-blur-[3px] bg-opacity-90 group-hover:w-40 pb-3 group-hover:transition duration-300 ease-out-in'>
                  <div className='h-[0.1rem] w-full bg-[#D7B95A] mb-3'></div>
                  {media.map((work, id) => {
                    return (
                      <li
                        key={work.link + id}
                        className='py-1 px-4 hover:bg-[#D7B95A] hover:text-[#064663]'
                      >
                        <a href={work.link}>{work.title}</a>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
            <div className=' hover:cursor-pointer flex flex-col items-start justify-center group'>
              <a href='/' className='text-gray-50'>
                Careers
              </a>
              <div className='-mt-[0.2rem] w-0 h-[0.1rem] bg-[#D7B95A] lg:group-hover:w-full group-hover:transition duration-300 ease-in-out'></div>
            </div>
            <div className=' hover:cursor-pointer flex flex-col items-start justify-center group'>
              <a href='/' className='text-gray-50'>
                Events
              </a>
              <div className='-mt-[0.2rem] w-0 h-[0.1rem] bg-[#D7B95A] lg:group-hover:w-full group-hover:transition duration-300 ease-in-out'></div>
            </div>
            <div className=' hover:cursor-pointer flex flex-col items-start justify-center group bg-[#D7B95A] px-4 py-3 rounded'>
              <button
                type='button'
                onClick={() => {
                  alert("In progress");
                }}
                className='text-black font-medium text-bold uppercase'
              >
                Donate ❤️
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className=' bg-[#064663] h-20 w-full'></div>
    </div>
  );
}
