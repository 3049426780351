import { useEffect } from "react";
import samson from "../assets/images/samson.jpg";

export default function OurStory() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <div
        className='my-10 lg:my-20 h-28 lg:h-32 bg-blue-200 w-full flex flex-row justify-start items-center bg-center bg-cover'
        style={{
          backgroundImage: `linear-gradient(75deg, black, #01293B , #02293B, orange)`,
        }}
      >
        <div className='lg:text-4xl text-2xl font-bold rounded-tr-2xl  pl-5 lg:pl-32 py-2 text-white uppercase font-sans'>
          Our Story
        </div>
      </div>
      <div className='pb-10 lg:pb-20 lg:px-32 text-lg lg:text-xl w-full '>
        <div className='p-2 lg:p-0'>
          Samson Balogun founded NEDEC in 2021 to reduce the suffering and death
          associated to neglected infectious diseases amongst underserved
          populations, and with the help of his supervisor Dr Justin Pachebat,
          he developed the vision of the organisation and sets its operational
          foundation. <br />
          He is leading this innovative solution-based organisation to ensure
          that neglected underserved populations in Nigeria receive necessary
          health interventions from health education and awareness to vaccine
          and mass drug administration. <br />
          Our solutions are evidence-based through our research and incorporates
          a One Health approach to advance a more sustainable outcomes across
          our programmatic areas. <br />
          NEDEC has partnered with Omeva consulting and has won the global One
          Health special prize of the United Nations FAO and Wageningen
          University to carry out an avian influenza awareness campaign through
          the development of an innovative ICT-based solution incorporating One
          Health principles.
          <br /> This pivotal study will be led to the control of Avian
          Influenza and other zoonotic diseases.
        </div>
        <div
          className='h-[20rem] w-full lg:w-[20rem] bg-cover bg-center mt-10'
          style={{ backgroundImage: `url(${samson})` }}
        ></div>
        <p className='p-1 text-xl font-bold text-[#064663]'>Samson Balogun</p>
      </div>
    </>
  );
}
