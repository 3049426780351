import { FaExclamationTriangle } from "react-icons/fa";
export default function PageNotFound() {
  return (
    // <div className="flex flex-row justify-center items-center font-bold h-screen">
    //   Link Restricted
    // </div>
    <div
      className='flex h-screen bg-cover bg-black items-center justify-center'
      style={{
        backgroundImage: `url(https://images.unsplash.com/photo-1581092921461-eab62e97a780?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)`,
      }}
    >
      <div className='text-white text-center flex flex-col justify-center items-center'>
        <FaExclamationTriangle size={100} color='red' />
        <h className='text-4xl font-bold mb-4'>Page not found.</h>
      </div>
    </div>
  );
}
