const OurWork = () => {
  return (
    <div>
      <div
        className='my-10 lg:my-20 h-28 lg:h-32 bg-blue-200 w-full flex flex-row justify-start items-center bg-center bg-cover'
        style={{
          backgroundImage: `linear-gradient(75deg, black, #01293B , #02293B, orange)`,
        }}
      >
        <div className='lg:text-4xl text-2xl font-bold rounded-tr-2xl  pl-5 lg:pl-32 py-2 text-white uppercase font-sans'>
          Our Work
        </div>
      </div>
    </div>
  );
};

export default OurWork;
