import zoonosis from "../assets/images/zoonosis.jpeg";
import antimicrobial from "../assets/images/antimicrobial.png";
import env from "../assets/images/env.jpeg";

const OneHealth = () => {
  return (
    <>
      <div
        className='my-10 lg:my-20 h-28 lg:h-32 bg-blue-200 w-full flex flex-row justify-start items-center bg-center bg-cover'
        style={{
          backgroundImage: `linear-gradient(75deg, black, #01293B , #02293B, orange)`,
        }}
      >
        <div>
          <div className='lg:text-4xl text-2xl font-bold rounded-tr-2xl  pl-5 lg:pl-32 pt-2 text-white uppercase font-sans'>
            Our Work
          </div>
          <p className='lg:text-lg text-xs rounded-tr-2xl  pl-5 lg:pl-32  text-white capitalize font-sans'>
            One Health {">"}
          </p>
        </div>
      </div>
      <div className='pb-10 lg:pb-20 lg:px-32 text-lg lg:text-xl w-full '>
        <div className='p-2 lg:p-0'>
          In a world interconnected by the health of humans, animals, and the
          environment, One Health emerges as a transformative solution for
          addressing associated health challenges and sustained impact. <br />{" "}
          This holistic strategy recognizes the inextricable links between
          interconnected realms, emphasizing collaboration to tackle shared
          health challenges.
          <br /> From zoonotic diseases to environmental degradation, One Health
          fosters integrated solutions, fostering a resilient and sustainable
          future.
          <br /> Join us in championing this paradigm shift for a healthier,
          more interconnected world.
        </div>
        <h1 className='p-2 uppercase mt-5 lg:mt-10 font-bold font-sans text-[#064663]'>
          zoonosis
        </h1>
        <div className='flex flex-col lg:flex-row gap-3 mt-10'>
          <img
            src={zoonosis}
            alt='Zoonosis'
            className='h-[20rem] w-full lg:w-[20rem]'
          />
          <div className='px-2'>
            Zoonotic diseases are infectious illnesses transmitted between
            animals and humans, and it disproportionately affect impoverished
            communities in low-income countries. Some of the most common
            zoonoses are prevalent among poor livestock workers, it has severe
            implications for both human health and the livelihoods of those on
            the margins. <br /> These diseases pose a substantial threat due to
            weak surveillance, limited awareness, and inadequate data, leading
            to underestimated burdens in developing nations like Nigeria.
            <br /> As we delve into addressing neglected zoonotic diseases, we
            must prioritize building robust surveillance systems, raising
            awareness, and fostering collaborative efforts to alleviate the
            impact of these diseases on the world's most vulnerable populations.{" "}
            <br /> These diseases impact human health in diverse ways, ranging
            from acute, life-threatening conditions like rabies to chronic
            illnesses with long-term consequences, emphasizing the importance of
            a One Health approach to address their complex dynamics.
          </div>
        </div>

        <h1 className='p-2 uppercase mt-5 lg:mt-10 font-bold font-sans text-[#064663]'>
          Antimicrobial Resistance
        </h1>
        <div className='flex flex-col lg:flex-row gap-3 mt-10'>
          <img
            src={antimicrobial}
            alt='Antimicrobial Resistance'
            className='h-[15rem] w-full lg:w-[20rem]'
          />
          <div className='px-2'>
            Antimicrobial resistance (AMR) poses a growing global threat to
            public health, demanding urgent and collaborative action.
            <br />
            This phenomenon, fueled by overuse and misuse of antimicrobials
            across human, animal, and environmental sectors, jeopardizes the
            efficacy of these life-saving drugs. AMR leads to treatment
            failures, increased healthcare costs, and heightened mortality
            rates. <br /> A One Health perspective, recognizing the
            interconnectedness of human, animal, and environmental health, is
            crucial in tackling this multifaceted issue. <br />
            The fight against AMR necessitates a unified front, transcending
            disciplinary boundaries. By adopting a One Health solution, we can
            safeguard the effectiveness of antimicrobials, protect public
            health, and ensure a sustainable future.
          </div>
        </div>

        <h1 className='lg:w-80 p-2 uppercase mt-5 lg:mt-10 font-bold font-sans text-[#064663]'>
          Environmental health and conservation
        </h1>
        <div className='flex flex-col lg:flex-row gap-3 mt-10'>
          <img
            src={env}
            alt='Environmental health and conservation'
            className='h-[15rem] w-full lg:w-[20rem]'
          />
          <div className='px-2'>
            In the face of escalating environmental challenges, the One Health
            concept emerges as a beacon of hope, advocating a synergistic
            approach to safeguarding the health of humans, animals, and the
            planet. <br />
            This collaborative strategy, bridging disciplines of medicine,
            ecology, and conservation, is imperative for addressing
            interconnected global threats.
            <br /> One Health encourages collaboration between scientists,
            policymakers, and communities to develop holistic solutions for a
            healthier planet. <br />
            Embracing the One Health approach is not merely a choice but a
            necessity in our quest for a sustainable and resilient future and by
            integrating environmental conservation into health strategies, we
            pave the way for a harmonious coexistence of all living beings.
          </div>
        </div>
      </div>
    </>
  );
};

export default OneHealth;
