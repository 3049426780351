import tropical from "../assets/images/tropical.jpg";

const NeglectedTropicalDisease = () => {
  return (
    <>
      <div
        className='my-10 lg:my-20 h-28 lg:h-32 bg-blue-200 w-full flex flex-row justify-start items-center bg-center bg-cover'
        style={{
          backgroundImage: `linear-gradient(75deg, black, #01293B , #02293B, orange)`,
        }}
      >
        <div>
          <div className='lg:text-4xl text-2xl font-bold rounded-tr-2xl  pl-5 lg:pl-32 pt-2 text-white uppercase font-sans'>
            Our Work
          </div>
          <p className='lg:text-lg text-xs rounded-tr-2xl  pl-5 lg:pl-32  text-white capitalize font-sans'>
            Neglected Tropical Diseases {">"}
          </p>
        </div>
      </div>
      <div className='pb-10 lg:pb-20 lg:px-32 text-lg lg:text-xl w-full '>
        <h1 className='p-2 uppercase mt-5 lg:mt-10 font-bold font-sans text-[#064663]'>
          Neglected tropical diseases
        </h1>
        <div className='flex flex-col lg:flex-row gap-3 mt-10'>
          <img
            src={tropical}
            alt='tropical'
            className='h-[15rem] w-full lg:w-[20rem]'
          />
          <div className='px-2'>
            Neglected Tropical Diseases (NTDs) afflict millions in impoverished
            regions, causing untold suffering and hindering socio-economic
            progress.
            <br /> A decisive call to action is needed to champion the
            integration of the One Health approach, recognizing the
            interconnectedness of human, animal, and environmental health. NTDs,
            often overshadowed by more prominent health concerns, affect the
            most vulnerable populations.
            <br /> By adopting a One Health perspective, we amplify our ability
            to combat NTDs comprehensively.
            <br /> This includes preventive measures, timely interventions, and
            sustainable solutions that extend beyond the conventional healthcare
            model.
            <br />
          </div>
        </div>
      </div>
    </>
  );
};

export default NeglectedTropicalDisease;
