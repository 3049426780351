import wash from "../assets/images/wash.jpg";

const WaterSanitation = () => {
  return (
    <>
      <div
        className='my-10 lg:my-20 h-28 lg:h-32 bg-blue-200 w-full flex flex-row justify-start items-center bg-center bg-cover'
        style={{
          backgroundImage: `linear-gradient(75deg, black, #01293B , #02293B, orange)`,
        }}
      >
        <div>
          <div className='lg:text-4xl text-2xl font-bold rounded-tr-2xl  pl-5 lg:pl-32 pt-2 text-white uppercase font-sans'>
            Our Work
          </div>
          <p className='lg:text-lg text-xs rounded-tr-2xl  pl-5 lg:pl-32  text-white capitalize font-sans'>
            Water, sanitation and hygiene {">"}
          </p>
        </div>
      </div>
      <div className='pb-10 lg:pb-20 lg:px-32 text-lg lg:text-xl w-full '>
        <h1 className='p-2 uppercase mt-5 lg:mt-10 font-bold font-sans text-[#064663]'>
          Water, sanitation and hygiene
        </h1>
        <div className='flex flex-col lg:flex-row gap-3 mt-10'>
          <img
            src={wash}
            alt='water sanitation and hygiene'
            className='h-[15rem] w-full lg:w-[20rem]'
          />
          <div className='px-2'>
            Access to safe water, proper sanitation, and adequate hygiene
            practices is pivotal for public health, particularly in Low- and
            Middle-Income Countries (LMICs).
            <br /> In these regions, the lack of such essentials poses
            significant challenges, contributing to the burden of preventable
            diseases and hindering socio-economic development.
            <br /> The absence of adequate sanitation facilities perpetuates the
            spread of diseases, particularly affecting vulnerable populations.
            <br />
            Implementing comprehensive sanitation solutions empowers
            communities, reduces disease transmission, and enhances overall
            living standards.
            <br /> Promoting hygiene education is a cornerstone of preventing
            infectious diseases. <br /> By cultivating awareness and encouraging
            proper hygiene practices, we empower individuals to protect
            themselves and their communities, thereby reducing the burden on
            healthcare systems. The ripple effects of improved water,
            sanitation, and hygiene extend beyond health outcomes. Access to
            these basic necessities catalyzes economic growth by reducing
            healthcare costs, increasing workforce productivity, and fostering a
            healthier and more educated population.
            <br />
          </div>
        </div>
      </div>
    </>
  );
};

export default WaterSanitation;
