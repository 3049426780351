import React from "react";
import { FiChevronUp, FiTwitter } from "react-icons/fi";
import { FaFacebookF, FaInstagram, FaWhatsapp } from "react-icons/fa";
import phone from "../../assets/images/phone.svg";
import vaslight from "../../assets/images/nedec_small1.png";
import { BsEnvelope } from "react-icons/bs";
import { GrLinkedinOption } from "react-icons/gr";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { Link as Linkto } from "react-scroll";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <>
      <div className='w-full h-full lg:h-96 bg-[#064663] relative'>
        <div className='flex flex-col lg:flex-row'>
          <div className='flex w-full lg:w-[70%] xl:w-2/3 justify-around gap-5  md:ml-0 md:gap-64 lg:gap-36 xl:gap-[2%] sm:pl-20 pl-5 pt-10'>
            <div className='text-white w-full lg:w-72 h-full flex flex-col gap-8 items-start justify-center lg:pt-3'>
              <h2 className='-mb-3 text-lg font-bold'>Contact Info</h2>

              <div className='flex gap-4'>
                <HiOutlineLocationMarker className='w-7 h-7 text-white' />
                <p className='font-medium  text-left'>Wuse II, Abuja</p>
              </div>

              <div className='flex gap-4'>
                <img src={phone} alt='phone' className='w-4 h-4 text-white' />
                <p className='font-medium  text-left'>
                  +234 810 833 9071, +44 7448 514194
                </p>
              </div>

              <div className='flex gap-4'>
                <BsEnvelope className='w-5 h-5 mt-1 text-white' />
                <p className='font-medium  text-left'>info@nedec.org</p>
              </div>
            </div>
            <div className='text-white w-full lg:w-60 h-full flex flex-col gap-3 items-start justify-center lg:pt-6'>
              <h2 className=' text-lg  font-bold'>Quick Links</h2>

              <Link
                className='font-medium hover:text-[#E4D56B] text-left '
                to='/about-us'
              >
                About Us
              </Link>

              <Link
                className='font-medium hover:text-[#E4D56B] text-left'
                to='/'
              >
                Our Work
              </Link>

              <Link
                className='font-medium hover:text-[#E4D56B] text-left'
                to='/'
              >
                Our Impact
              </Link>

              <Link
                className='font-medium hover:text-[#E4D56B] text-left'
                to='/'
              >
                Media
              </Link>

              <Link
                className='font-medium hover:text-[#E4D56B] text-left'
                to='/'
              >
                Careers
              </Link>
              <Link
                className='font-medium hover:text-[#E4D56B] text-left'
                to='/'
              >
                Events
              </Link>
            </div>
          </div>
          <div className='text-white h-full flex flex-col gap-16 px-5 py-10 sm:p-20 lg:px-0 items-start justify-center'>
            <div className='lg:mr-20'>
              <h2 className='  text-left mb-5 font-bold'>Social Links</h2>
              <div className='flex gap-5 opacity-90 '>
                <Link
                  className='p-2 border-white rounded-md border-[1px]'
                  to='/'
                >
                  <FaFacebookF className='w-4 h-4 text-white' />
                </Link>
                <Link
                  className='p-2 border-white rounded-md border-[1px]'
                  to='/'
                >
                  <FaInstagram className='w-4 h-4 text-white' />
                </Link>
                <a
                  className='p-2 border-white rounded-md border-[1px]'
                  href='https://twitter.com/nedec_ng'
                >
                  <FiTwitter className='w-4 h-4 text-white' />
                </a>
                <Link
                  className='p-2 border-white rounded-md border-[1px]'
                  to='/'
                >
                  <FaWhatsapp className='w-4 h-4 text-white' />
                </Link>
                <a
                  className='p-2 border-white rounded-md border-[1px]'
                  href='https://www.linkedin.com/company/neglected-disease-eradication-campaign-nedec/'
                >
                  <GrLinkedinOption className='w-4 h-4 text-white' />
                </a>
              </div>
            </div>

            <div className='flex flex-col items-center justify-center'>
              <img src={vaslight} alt='VASTECH' className='h-8' />
            </div>
          </div>
        </div>
        <Linkto
          to='home'
          duration={300}
          smooth={true}
          className=' hover:cursor-pointer w-12 h-12 bg-white text-white hover:text-[#064663] rounded-md absolute right-5 bottom-10 lg:bottom-20 lg:right-24 flex justify-center items-center bg-opacity-10 hover:bg-[#E4D56B] '
        >
          <FiChevronUp className=' w-6 h-6' />
        </Linkto>
      </div>
      <div className='h-20 w-full bg-[#003953] flex justify-center items-center'>
        <p className='font-medium text-sm text-white'>
          Copyright &copy; 2023, all right reserved to NEDEC Ltd.
        </p>
      </div>
    </>
  );
}
