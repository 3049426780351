import { Link } from "react-router-dom";
import samson from "../assets/images/samson.jpg";
import hameed from "../assets/images/hameed.jpg";
import empty from "../assets/images/empty.jpeg";
import kizito from "../assets/images/kizito.png";

const OurTeam = () => {
  return (
    <div>
      <div
        className='my-10 lg:my-20 h-28 lg:h-32 bg-blue-200 w-full flex flex-row justify-start items-center bg-center bg-cover'
        style={{
          backgroundImage: `linear-gradient(75deg, black, #01293B , #02293B, orange)`,
        }}
      >
        <div className='lg:text-4xl text-2xl font-bold rounded-tr-2xl  pl-5 lg:pl-32 py-2 text-white uppercase font-sans'>
          Our Team
        </div>
      </div>
      <div className='px-2 pb-10 lg:pt-10 lg:px-32 grid grid-cols-1 lg:grid-cols-3  gap-3 lg:gap-20'>
        <div className='group'>
          <div className='flex flex-col group-hover:hidden h-fit lg:h-[27rem]'>
            <img src={samson} alt='Samson' className='w-full h-80 lg:w-80' />
            <h1 className='font-bold text-xl text-[#064663]'>Samson Balogun</h1>
            <p className='font-bold mb-3'>
              Founder and Executive Director of NEDEC
            </p>
            {/* <div className='h-[0.05rem] mb-3 w-full bg-gray-100'></div> */}
            {/* <p>Email:</p> */}
          </div>
          <div className=' hidden group-hover:flex group-hover:flex-col justify-around item w-full lg:h-[27rem] lg:w-80 bg-[#064663] text-white p-4'>
            <p className=''>
              Samson in his postgraduate research developed a novel multiplex
              PCR diagnostic tool for rapid and sensitive diagnostics of
              Cryptosporidium parasites which by extension can be replicated in
              the diagnosis of other enteric parasites. In 2023, Samson and his
              team of three researchers won the global One Health special prize
              at World Food Forum in Rome backed by the United Nations FAO and
              Wageningen University....
            </p>
            <Link to='#' className='hover:text-blue-500'>
              See More
            </Link>
          </div>
        </div>

        <div className='group'>
          <div className='flex flex-col group-hover:hidden h-fit lg:h-[27rem]'>
            <img src={kizito} alt='Samson' className='w-full h-80 lg:w-80' />
            <h1 className='font-bold text-xl text-[#064663]'>
              Dr Bello Kizito
            </h1>
            <p className='font-bold text-lg mb-3'>Program Lead, One Health</p>
            {/* <div className='h-[0.05rem] mb-3 w-full bg-gray-100'></div> */}
            {/* <p>Email:</p> */}
          </div>
          <div className=' hidden group-hover:flex group-hover:flex-col justify-around item w-full lg:h-[27rem] lg:w-80 bg-[#064663] text-white p-4'>
            <p className=''>
              Dr. Bello Kizito Eneye is an esteemed individual in the field of
              modern translational medical research and academia. He currently
              works as a Senior Lecturer at Kogi State University in Anyigba,
              Nigeria. Also, he holds the position of Senior Research Fellow at
              the Department of Medical Microbiology and Parasitology at
              Universiti Sains Malaysia. His dedication to promoting knowledge
              is additionally emphasised by his position as a Research Fellow in
              the School of Medical Sciences at Universiti Sains Malaysia....
            </p>
            <Link to='#' className='hover:text-blue-500'>
              See More
            </Link>
          </div>
        </div>

        <div className='group'>
          <div className='flex flex-col group-hover:hidden h-fit lg:h-[27rem]'>
            <img src={hameed} alt='Samson' className='w-full h-80 lg:w-80' />
            <h1 className='font-bold text-xl text-[#064663]'>
              Hameed Abdulmajeed
            </h1>
            <p className='font-bold text-lg mb-3'>Head of Operations</p>
            {/* <div className='h-[0.05rem] mb-3 w-full bg-gray-100'></div> */}
            {/* <p>Email:</p> */}
          </div>
          <div className='hidden group-hover:block w-full lg:h-[27rem] lg:w-80 bg-[#064663]'></div>
        </div>

        <div className='group'>
          <div className='flex flex-col group-hover:hidden h-fit lg:h-[27rem]'>
            <img src={empty} alt='Samson' className='w-full h-80 lg:w-80' />
            <h1 className='font-bold text-xl text-[#064663]'>
              Dr Ramatu Nasiru
            </h1>
            <p className='font-bold text-lg mb-3'>Head of Programs</p>
            {/* <div className='h-[0.05rem] mb-3 w-full bg-gray-100'></div> */}
            {/* <p>Email:</p> */}
          </div>
          <div className='hidden group-hover:block w-full lg:h-[27rem] lg:w-80 bg-[#064663] text-white p-2'></div>
        </div>

        <div className='group'>
          <div className='flex flex-col group-hover:hidden h-fit lg:h-[27rem]'>
            <img src={empty} alt='Samson' className='w-full h-80 lg:w-80' />
            <h1 className='font-bold text-xl text-[#064663]'>
              Dr Clement Yaro
            </h1>
            <p className='font-bold text-lg mb-3'>
              Head of Research and Innovation
            </p>
            {/* <div className='h-[0.05rem] mb-3 w-full bg-gray-100'></div> */}
            {/* <p>Email:</p> */}
          </div>
          <div className='hidden group-hover:block w-full lg:h-[27rem] lg:w-80 bg-[#064663] text-white p-2'></div>
        </div>
      </div>
    </div>
  );
};

export default OurTeam;
