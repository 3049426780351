import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import omeva from "../assets/images/omeva.jpeg";
import fao from "../assets/images/fao.jpg";
import aber from "../assets/images/aber.jpg";
import wag from "../assets/images/wag.png";

// Import Swiper styles

// import required modules
// import { Pagination } from "swiper/modules";

import SwiperCore, {
  Navigation,
  Pagination,
  Mousewheel,
  Keyboard,
  EffectFade,
  Autoplay,
} from "swiper";

export default function Sponsors() {
  return (
    <>
      <h1 className='w-full text-center text-[#064663] font-bold text-2xl lg:text-4xl pt-12 lg:py-5 '>
        Pertners
      </h1>
      <div className='h-32 lg:h-52 mb-4 mx-4 lg:mx-52'>
        <Swiper
          slidesPerView={3}
          spaceBetween={30}
          cssMode={true}
          navigation={true}
          mousewheel={true}
          keyboard={true}
          autoplay={{
            delay: 4000,
            disableOnInteraction: true,
          }}
          pagination={{
            clickable: true,
          }}
          modules={[Navigation, Mousewheel, Pagination, Keyboard, Autoplay]}
          className='w-full h-full'
        >
          <SwiperSlide>
            <div className='h-full w-full flex justify-center items-center'>
              <img src={fao} alt='fao' className='h-20 lg:h-40' />
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className='h-full w-full flex justify-center items-center'>
              <img src={aber} alt='aberyst' className='h-20 lg:h-40' />
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className='h-full w-full flex justify-center items-center'>
              <img src={wag} alt='wageningen' className='h-20 lg:h-40' />
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className='h-full w-full flex justify-center items-center'>
              <img src={omeva} alt='omeva' className='h-20 lg:h-40' />
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className='h-full w-full flex justify-center items-center'>
              <img src={fao} alt='fao' className='h-20 lg:h-40' />
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className='h-full w-full flex justify-center items-center'>
              <img src={aber} alt='aberyst' className='h-20 lg:h-40' />
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className='h-full w-full flex justify-center items-center'>
              <img src={wag} alt='wageningen' className='h-20 lg:h-40' />
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className='h-full w-full flex justify-center items-center'>
              <img src={omeva} alt='omeva' className='h-20 lg:h-40' />
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </>
  );
}
