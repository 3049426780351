import { CheckCircleIcon } from "@heroicons/react/solid";
import boy_dad2 from "../assets/images/boy_dad2.jpg";

const SectionOne = () => {
  return (
    <>
      <div className='w-full lg:w-1/2 '>
        <img src={boy_dad2} alt='DAD' className='w-full lg:w-[35rem]' />
      </div>
      <div className='w-full px-4 lg:w-1/2 min-h-full grid grid-col-1 justify-center items-center gap-0 lg:ml-10 lg:mr-20 py-5 xl:py-20'>
        <h1 className='text-left lg:text-center text-2xl lg:text-5xl font-bold text-[#064663] w-full lg:mb-5'>
          Contagion To Containment
        </h1>
        <p className='font-medium text-[#064663] pb-4 my-4 lg:my-0'>
          Contagion to Containment approach is a critical strategy for
          addressing neglected diseases in Africa. By containing the spread of
          neglected diseases, diagnosing and treating those who are affected,
          preventing the recurrence of neglected diseases, and investing in
          research and development, we can make progress in reducing the burden
          of neglected diseases on the continent. Addressing neglected diseases
          in Africa is not only a matter of public health but also an essential
          step towards achieving sustainable development and reducing poverty in
          the region.
        </p>
        <div className=' grid grid-cols-1 md:grid-cols-2 text-[#064663] w-full font-medium'>
          <div className=' flex gap-1 items-center mb-4'>
            <CheckCircleIcon className='text-[#D7B95A] h-5 w-5' />
            <p className='text-sm lg:text-sm'>Health Education Campaigns</p>
          </div>
          <div className=' flex gap-1 items-center mb-4'>
            <CheckCircleIcon className='text-[#D7B95A] h-5 w-5' />
            <p className='text-sm lg:text-sm'>Free Consultation</p>
          </div>
          <div className=' flex gap-1 items-center mb-4'>
            <CheckCircleIcon className='text-[#D7B95A] h-5 w-5' />
            <p className='text-sm lg:text-sm'>Community-based Surveillance</p>
          </div>
          <div className=' flex gap-1 items-center mb-4'>
            <CheckCircleIcon className='text-[#D7B95A] h-5 w-5' />
            <p className='text-sm lg:text-sm'>Collaboration and Advocacy</p>
          </div>
        </div>
        <div className='my-4'>
          <button className='py-5 px-7 bg-[#064663] text-white text-sm rounded hover:bg-[#003953] font-medium'>
            Make An Appointment
          </button>
        </div>
      </div>
    </>
  );
};

export default SectionOne;
