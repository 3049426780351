import { Routes, Route } from "react-router-dom";
import AboutUs from "../pages/AboutUs";

//pages
import Home from "../pages/Home";
import Maintenance from "../pages/Maintenance";
import Index from "../pages/Maintenance";
import NeglectedTropicalDisease from "../pages/NeglectedTropicalDisease";
import OneHealth from "../pages/OneHealth";
import OurStory from "../pages/OurStory";
import OurTeam from "../pages/OurTeam";
import OurWork from "../pages/OurWork";
import PageNotFound from "../pages/PageNotFound";
import ResearchInnovation from "../pages/ResearchInnovation";
import WaterSanitation from "../pages/WaterSanitation";

export default function Routers() {
  return (
    <Routes>
      <Route element={<Home />} path='/home' />
      <Route element={<Home />} path='/' />
      <Route element={<AboutUs />} path='/about-us' />
      <Route element={<OurStory />} path='/our-story' />
      <Route element={<OurWork />} path='/our-work' />
      <Route element={<OurTeam />} path='/our-team' />
      <Route element={<OneHealth />} path='/our-work/one-health' />
      <Route
        element={<NeglectedTropicalDisease />}
        path='/our-work/neglected-tropical'
      />
      <Route element={<WaterSanitation />} path='/our-work/water-sanitation' />
      <Route
        element={<ResearchInnovation />}
        path='/our-work/research-innovation'
      />

      {/* <Route element={<Maintenance />} path='/' />
      <Route element={<Maintenance />} path='/home' /> */}
      <Route element={<PageNotFound />} path='*' />
    </Routes>
  );
}
