import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Mousewheel, Keyboard, Autoplay } from "swiper";
import quote from "../assets/images/quote.svg";
import { FiChevronUp, FiChevronRight } from "react-icons/fi";

const ClientSlidder = () => {
  return (
    <>
      <div
        id='clients'
        className='w-full h-fit text-center text-[#064663] lg:mt-14 lg:pb-3 pt-5'
      >
        {/* <h1 className='font-bold text-2xl lg:text-5xl pt-12 lg:mt-14 lg:py-7 '>
          Clients Stories
        </h1>
        <div className='h-[20rem] lg:h-[30rem] bg-[#064663] my-4 mx-4 lg:mx-52'>
          <Swiper
            cssMode={true}
            navigation={true}
            mousewheel={true}
            keyboard={true}
            autoplay={{
              delay: 4000,
              disableOnInteraction: true,
            }}
            pagination={{
              clickable: true,
            }}
            modules={[Navigation, Mousewheel, Pagination, Keyboard, Autoplay]}
            className='w-full h-full'
          >
            <SwiperSlide className='bg-[#064663]'>
              <div className=' bg-white text-[#064663] lg:mx-[7.5%] h-full'>
                <div className='flex justify-center items-center h-full gap-2 mx-4'>
                  <div className='ml-2 flex-none h-fit sm:-mt-20 -mt-40 lg:-mt-20'>
                    <img
                      src={quote}
                      alt='quote'
                      className='w-10 h-10 lg:w-16 lg:h-16 xl:w-20 xl:h-20 mr-3 opacity-60 '
                    />
                  </div>
                  <div className='flex flex-col items-start gap-5'>
                    <div className='flex gap-3 '>
                      <div className='w-14 h-14 bg-white bg-ceo bg-cover rounded-full '></div>
                      <div className='flex flex-col text-left items-start '>
                        <h1 className='font-bold text-xl'>
                          Abdulmajeed Hameed
                        </h1>
                        <div>
                          <p className=' font-light text-[0.8rem] text-[#064663]'>
                            CTO NEDEC
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className='w-[90%] lg:w-[30rem] '>
                      <p className='font-medium italic text-sm text-[#064663] text-left'>
                        "We can't understand how we've been living without
                        NEDEC. Thank you for making it painless, pleasant and
                        most of all hassle free! I am so pleased with their
                        Services"
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide className='bg-[#064663]'>
              <div className=' bg-white text-[#064663] lg:mx-[7.5%] h-full'>
                <div className='flex justify-center items-center h-full gap-2 mx-4'>
                  <div className='ml-2 flex-none h-fit sm:-mt-20 -mt-40 lg:-mt-20'>
                    <img
                      src={quote}
                      alt='quote'
                      className='w-10 h-10 lg:w-16 lg:h-16 xl:w-20 xl:h-20 mr-3 opacity-60 '
                    />
                  </div>
                  <div className='flex flex-col items-start gap-5'>
                    <div className='flex gap-3 '>
                      <div className='w-14 h-14 bg-white bg-ceo bg-cover rounded-full '></div>
                      <div className='flex flex-col text-left items-start '>
                        <h1 className='font-bold text-xl'>
                          Samson Omeiza Balogun{" "}
                        </h1>
                        <div>
                          <p className=' font-light text-[0.8rem] text-[#064663]'>
                            CEO NEDEC
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className='w-[90%] lg:w-[30rem] '>
                      <p className='font-medium italic text-sm text-[#064663] text-left'>
                        "We can't understand how we've been living without
                        NEDEC. Thank you for making it painless, pleasant and
                        most of all hassle free! I am so pleased with their
                        Services"
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div> */}
        <div className='my-10 lg:my-20 border-y-[1.5px] mx-4 lg:mx-40 text-[#064663]'>
          <h2 className='mx-4 md:mx-8 xl:mx-40 mt-10 lg:mt-14 font-[600] text-2xl xl:text-3xl text-left md:text-center'>
            {/* Are you ready to join the campaign team and fight against Neglected
            Diseases? If so, make an enquiry now. */}
            Join our campaign team to fight against Neglected Diseases
          </h2>
          <div className='w-full flex justify-center items-center pb-12 lg:pb-16 pt-10'>
            <button
              onClick={() => {
                alert("In progress");
              }}
              className='flex gap-2 rounded justify-center items-center bg-[#D7B95A] text-black pl-5 pr-4 py-3 text-sm font-normal'
            >
              <p className='font-[600] uppercase'> Donate ❤️</p>
              {/* <FiChevronRight className='w-5 h-5 mt-[0.2rem]' /> */}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ClientSlidder;
