import React, { useEffect, useRef } from "react";
import woman_river from "../assets/images/woman_river.jpg";
import yellow_student from "../assets/images/yellow_student.jpg";
import children from "../assets/images/children.jpg";

import dad from "../assets/images/sick_dad.jpg";
import swamp from "../assets/images/swamp.jpg";
import Sponsors from "../components/Sponsors";

import SwiperCore, {
  Navigation,
  Pagination,
  Mousewheel,
  Keyboard,
  EffectFade,
  Autoplay,
} from "swiper";

import { ReactComponent as Support } from "../assets/images/icons/support.svg";
import { ReactComponent as Checked } from "../assets/images/icons/checked.svg";
import { ReactComponent as Hand } from "../assets/images/icons/hand.svg";

import { Swiper, SwiperSlide } from "swiper/react";

import { useInView } from "react-intersection-observer";

// Import Swiper styles
import "swiper/css";
// import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import Counter from "../components/Counter";
import ClientSlidder from "../components/ClientSlidder";
import SectionOne from "../components/SectionOne";
import { Link } from "react-router-dom";

export default function Home() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const [ref1, inView1] = useInView({ threshold: 0, triggerOnce: true });

  SwiperCore.use([EffectFade]);

  const swiperRef = useRef(null);

  const onSwiper = (swiper) => {
    // store swiper instance reference
    swiperRef.current = swiper;
  };

  const fadeEffect = {
    crossFade: true,
    ease: "ease-in-out",
  };

  return (
    <>
      <div className='bg-white h-[35rem] w-full flex justify-between '>
        <div className='pl-[6%] lg:flex lg:flex-col lg:justify-center lg:items-start py-32 w-1/2 hidden'>
          <div className='flex justify-center items-center gap-1'>
            <div className='h-[0.07rem] w-8 bg-[#064663]'></div>
            <p>Make Disease Prevention A Top Priority</p>
          </div>
          <h1 className=' font-bold text-5xl mt-4 text-[#041C32] leading-tight'>
            Welcome to Neglected Disease Eradication Campaign (NEDEC)
            <div className='text-lg font-light mt-3'>
              We are a not for profit organization based in Nigeria, working to
              reduce the detrimental impact of Neglected Diseases in poor
              undeserved communities by strengthening capacities of primary
              health care workers, and engaging in public health education,
              sensitization, advocacy and research while also advancing and
              promoting WASH and an Integrated One Health approach towards
              Disease elimination.
            </div>
          </h1>

          <button className='py-5 px-7 my-10 bg-[#064663] text-white text-sm rounded hover:bg-[#003953] '>
            Make An Appointment
          </button>
        </div>

        <div className='h-full w-full lg:w-1/2 flex gap-3 relative'>
          <Swiper
            spaceBetween={0}
            effect='fade'
            speed={1000}
            fadeEffect={fadeEffect}
            centeredSlides={true}
            autoplay={{
              delay: 4000,
              disableOnInteraction: true,
            }}
            onSwiper={onSwiper}
            pagination={{
              clickable: false,
            }}
            modules={[Autoplay]}
            className='mySwiper w-full h-full block lg:hidden'
          >
            <SwiperSlide>
              <div
                className=' h-full bg-black bg-cover bg-no-repeat bg-center'
                style={{
                  backgroundImage: `linear-gradient(89.89deg, #161616 0.09%, rgba(217, 217, 217, 0) 99.9%), url(${children})`,
                }}
              ></div>
            </SwiperSlide>
            <SwiperSlide>
              <div
                className=' h-full bg-black bg-cover bg-no-repeat bg-center'
                style={{
                  backgroundImage: `linear-gradient(89.89deg, #161616 0.09%, rgba(217, 217, 217, 0) 99.9%), url(${yellow_student})`,
                }}
              ></div>
            </SwiperSlide>
            <SwiperSlide>
              <div
                className=' h-full bg-black bg-cover bg-no-repeat bg-center'
                style={{
                  backgroundImage: `linear-gradient(89.89deg, #161616 0.09%, rgba(217, 217, 217, 0) 99.9%), url(${swamp})`,
                }}
              ></div>
            </SwiperSlide>
            <SwiperSlide>
              <div
                className=' h-full bg-black bg-cover bg-no-repeat bg-center'
                style={{
                  backgroundImage: `linear-gradient(89.89deg, #161616 0.09%, rgba(217, 217, 217, 0) 99.9%), url(${woman_river})`,
                }}
              ></div>
            </SwiperSlide>
            <SwiperSlide>
              <div
                className=' h-full bg-black bg-cover bg-no-repeat bg-center'
                style={{
                  backgroundImage: `linear-gradient(89.89deg, #161616 0.09%, rgba(217, 217, 217, 0) 99.9%), url(${dad})`,
                }}
              ></div>
            </SwiperSlide>
          </Swiper>
          <div className='hidden lg:flex lg:flex-col justify-center py-3 gap-3 md:ml-28 lg:ml-40 w-1/4 h-full'>
            <div
              className='hidden lg:block h-36 w-36 bg-[#064663] rounded-full bg-cover bg-no-repeat bg-center'
              style={{ backgroundImage: `url(${yellow_student})` }}
            ></div>
            <div className='flex justify-end w-full'>
              <div className='h-24 w-24 bg-[#EADDC0] rounded-tl-[88px]'></div>
            </div>
            <div
              className=' h-36 w-36 bg-[#064663] rounded-bl-[88px] bg-cover bg-no-repeat bg-center'
              style={{ backgroundImage: `url(${swamp})` }}
            ></div>
          </div>
          <div className=' hidden lg:flex lg:flex-col gap-3 justify-center items-center w-3/4 h-full'>
            <div
              className='bg-[#064663] h-64 w-full rounded-tl-[88px] bg-cover bg-no-repeat bg-center'
              style={{ backgroundImage: `url(${children})` }}
            ></div>
            <div className='flex gap-3 w-full h-fit'>
              <div
                className=' h-36 w-36 bg-[#064663] rounded-br-[88px] bg-cover bg-no-repeat bg-center'
                style={{ backgroundImage: `url(${woman_river})` }}
              ></div>
              <div
                className=' h-36 w-36 bg-[#064663] rounded-full  bg-cover bg-no-repeat bg-center'
                style={{ backgroundImage: `url(${dad})` }}
              ></div>
            </div>
          </div>
          <div className='absolute block lg:hidden h-full w-full z-10'>
            <div className='text-gray-200 px-4 flex flex-col justify-between py-10 sm:py-20 items-center h-full w-full'>
              <div className='flex flex-col justify-start items-start animate-slideInFromLeft py-16 w-full h-full  gap-1'>
                <div className='flex justify-center items-center gap-2'>
                  <div className='h-[0.07rem] w-5 bg-white'></div>
                  <p className='text-xs'>
                    Make Disease Prevention A Top Priority
                  </p>
                </div>
                <h1 className=' font-bold text-3xl mt-4 text-white leading-tight'>
                  Welcome to Neglected Disease Eradication Campaign (NEDEC){" "}
                  {/* <br /> */}
                  <div className='text-base font-thin mt-3'>
                    We are a not for profit organization based in Nigeria,
                    working to reduce the detrimental impact of Neglected
                    Diseases and strengthening local health systems.
                  </div>
                  {/* The Importance of Sensitization, Education, and Community
                  Empowerment */}
                </h1>
                <button className='text-sm py-4 px-7 border-2 border-white rounded mt-20 font-medium'>
                  Make An Appointment
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='bg-gray-100 w-full h-full lg:mb-20 lg:mt-10'>
        <div className='flex flex-col justify-center items-center w-full h-full'>
          <div className='flex flex-col gap-5 lg:flex-row lg:gap-8 justify-between items-center w-full h-full px-4 lg:px-20 my-10 lg:my-20'>
            <div className='leading-6 h-[26rem] px-4 py-10 flex flex-col justify-evenly items-center text-[#064663] bg-white w-full lg:w-1/3 border shadow-md rounded'>
              <Support className='h-14 fill-current' />

              <h2 className='text-xl lg:text-2xl font-bold my-5'>
                {" "}
                Mass Drug Administration (MDA)
              </h2>
              <div className=' font-medium'>
                These programs involve distributing preventive and curative
                drugs to populations at risk of neglected diseases, such as
                schistosomiasis and lymphatic filariasis. MDA programs have been
                successful in reducing the burden of these diseases in some
                areas.
              </div>
              <button className='border-2 py-3 px-5 border-[#064663] mt-7 rounded font-medium'>
                Learn More
              </button>
            </div>
            <div className='h-[26rem] leading-6 px-4 py-10 flex flex-col justify-evenly items-center text-[#064663] bg-white w-full lg:w-1/3 border shadow-md rounded'>
              <Hand className='h-14 fill-current' />

              <h2 className='text-xl lg:text-2xl font-bold my-5'>
                {" "}
                Research and Development
              </h2>
              <div className=' font-medium'>
                Neglected diseases often lack effective treatments and vaccines.
                Therefore, research and development programs can focus on
                developing new drugs and vaccines, as well as improving
                diagnostic tools and understanding the causes of disease
                transmission.
              </div>
              <button className='border-2 py-3 px-5 border-[#064663] mt-7 rounded font-medium'>
                Learn More
              </button>
            </div>
            <div className='h-[26rem] leading-6 px-4 py-10 flex flex-col justify-evenly items-center text-[#064663] bg-white w-full lg:w-1/3 border shadow-md rounded'>
              <Checked className='h-14 fill-current' />
              <h2 className='text-xl lg:text-2xl font-bold my-5'>
                {" "}
                Vector Control
              </h2>
              <div className=' font-medium'>
                Many neglected diseases are transmitted by insect vectors, such
                as mosquitoes and sandflies. Vector control programs aim to
                reduce the number of disease-carrying insects through measures
                such as insecticide-treated bed nets and indoor residual
                spraying.
              </div>
              <button className='border-2 py-3 px-5 border-[#064663] mt-7 rounded font-medium'>
                Learn More
              </button>
            </div>
          </div>
          <div className={`hidden lg:block text-[#064663] w-full px-20`}>
            {/* <div className='text-5xl font-bold counter flex justify-between w-full px-20 pb-10'>
              <div className='flex flex-col gap-1'>
                <Counter counter={950} />
                <p className='text-gray-400 text-sm font-light'>
                  Country Coverage
                </p>
              </div>

              <div className='flex flex-col gap-1'>
                <Counter counter={700} />
                <p className='text-gray-400 text-sm font-light'>
                  Client Satisfaction
                </p>
              </div>

              <div className='flex flex-col gap-1'>
                <Counter counter={63} />
                <p className='text-gray-400 text-sm font-light'>
                  Expert Consultant
                </p>
              </div>

              <div className='flex flex-col gap-1'>
                <Counter counter={22} />
                <p className='text-gray-400 text-sm font-light'>
                  Winning Awards
                </p>
              </div>

            </div> */}
          </div>
        </div>
      </div>
      <div
        ref={ref1}
        className={`flex flex-col lg:flex-row w-full h-fit lg:mb-20  ${
          inView1 ? " slide-up" : " opacity-0"
        }`}
      >
        <SectionOne />
      </div>
      <div className='bg-gray-50 z-10 relative'>
        <ClientSlidder />
      </div>
      <div className='my-10'>
        <Sponsors />
      </div>
    </>
  );
}
