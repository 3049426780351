import { useEffect } from "react";
import doc from "../assets/images/yellow_student.jpg";
import nedec_photo from "../assets/images/nedec_photo.jpg";
import arrow from "../assets/images/arrowright.svg";
import med from "../assets/images/medication.jpg";
import crowd from "../assets/images/crowd.jpg";
import two_ladies from "../assets/images/two_ladies.jpg";

export default function AboutUs() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <div
        className='h-28 mt-10 lg:mt-20 bg-blue-200 w-full flex flex-row justify-start items-center bg-center bg-cover'
        style={{
          backgroundImage: `linear-gradient(45.89deg, #161616 0.09%, rgba(217, 217, 217, 0) 99.9%), url(${doc})`,
        }}
      >
        <div className='lg:text-4xl text-2xl font-bold font-sans uppercase rounded-tr-2xl px-5 lg:px-20 py-2 text-white'>
          About Us
        </div>
      </div>
      <div className='my-14 lg:my-32 w-full h-full lg:h-[30rem] flex flex-col-reverse lg:flex-row-reverse px-4 lg:px-20 gap-8'>
        <div
          className='w-full h-[30rem] lg:w-1/2 bg-cover bg-center'
          style={{ backgroundImage: `url(${two_ladies})` }}
        ></div>
        <div className='w-full text-lg h-full 2xl:text-2xl font-semibold text-gray-700 lg:h-[30rem] lg:w-1/2'>
          <h1 className='text-[#064663] text-xl 2xl:text-4xl font-bold mb-5 lg:mb-7'>
            Neglected Disease Eradication Campaign ( NEDEC)
          </h1>
          We are committed to playing an active role in the elimation of
          zoonotic and parasitic Neglected tropical diseases (NTDs) in poor
          undeserved communities in Nigeria. <br /> <br /> We do this by
          promoting and facilitating WASH practises, advancing One Health
          education, partnerships and engaging in health policy advocacy. <br />{" "}
          <br />
          At NEDEC, we are stregthen by our vision for a resilient health system
          for poor and undeserved populations, thereby creating a level playing
          ground for all to reach their full potentials. <br /> <br /> We
          acknowledge the impact of research and innovation in building a
          sustainable health system and development, making it One of our
          strategic objectives. Read our current Strategic plan for 2021-2025).
        </div>
      </div>
      <div className='px-4 lg:px-20 pb-10 lg:pb-16 mb-10 lg:mb-20 bg-[#E5F1F4]'>
        <div className='w-full my-5 lg:py-14 py-10'>
          <h1 className='text-xl lg:text-4xl text-[#064663] font-bold mb-2'>
            Our Mission, Vision & Values
          </h1>
          <div className='flex gap-3'>
            <div className='w-20 h-[0.15rem] bg-[#D7B95A] rounded-lg'></div>
            <div className='h-[0.15rem] bg-opacity-80 w-full rounded-lg'></div>
          </div>
          <div className='text-justify lg:text-xl font-semibold mt-4 text-gray-700'>
            We are on a mission to eradicate neglected diseases, dedicating
            ourselves to relieving the suffering of communities affected by
            these ailments. We strive to disrupt the cycle of neglect by
            offering comprehensive support, promoting awareness, and
            implementing innovative solutions that empower individuals and
            communities. <br />
            <br />
            Our vision is to see a world where neglected diseases are wiped out,
            and all individuals, regardless of their socio-economic status,
            enjoy access to high-quality healthcare. We envision communities
            liberated from the burden of neglected diseases, where people can
            lead healthy and fulfilling lives without the constant threat of
            preventable illnesses. <br />
            <br />
            our organizational identity is a tapestry of values—integrity,
            sincerity, honesty, accountability, teamwork, and collaboration.
            These values guide us as we strive to create a world free from the
            burden of neglected diseases, where every individual has the
            opportunity to lead a healthy and fulfilling life
          </div>
        </div>

        <div className='grid grid-cols-1 lg:grid-cols-3 gap-10 lg:gap-32 mt-5 lg:mt-10'>
          <div className='flex flex-col gap-2'>
            <img src={crowd} alt='Our Vision' className='rounded' />
            <a
              href='/our-story'
              className='text-[#064663] group hover:text-blue-500 text-lg lg:text-2xl font-bold inline-flex items-center gap-2 w-full p-1'
            >
              Our Story{" "}
              <img
                src={arrow}
                className='w-5 h-5 p-[0.1rem] bg-[#064663] group-hover:bg-blue-500 rounded-full'
              />
            </a>
          </div>
          <div className='flex flex-col gap-2'>
            <img src={med} alt='Our Vision' className='rounded' />
            <a
              href='#'
              className='text-[#064663] group hover:text-blue-500 text-lg lg:text-2xl font-bold inline-flex items-center gap-2 w-full p-1'
            >
              Our Impact{" "}
              <img
                src={arrow}
                className='w-5 h-5 p-[0.1rem] bg-[#064663] group-hover:bg-blue-500 rounded-full'
              />
            </a>
          </div>
          <div className='flex flex-col gap-2'>
            <img src={nedec_photo} alt='Our Vision' className='rounded' />
            <a
              href='/our-team'
              className='text-[#064663] group hover:text-blue-500 text-lg lg:text-2xl font-bold inline-flex items-center gap-2 w-full p-1'
            >
              Our Team{" "}
              <img
                src={arrow}
                className='w-5 h-5 p-[0.1rem] bg-[#064663] group-hover:bg-blue-500 rounded-full'
              />
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
