import research from "../assets/images/research.jpeg";

const ResearchInnovation = () => {
  return (
    <>
      <div
        className='my-10 lg:my-20 h-28 lg:h-32 bg-blue-200 w-full flex flex-row justify-start items-center bg-center bg-cover'
        style={{
          backgroundImage: `linear-gradient(75deg, black, #01293B , #02293B, orange)`,
        }}
      >
        <div>
          <div className='lg:text-4xl text-2xl font-bold rounded-tr-2xl  pl-5 lg:pl-32 pt-2 text-white uppercase font-sans'>
            Our Work
          </div>
          <p className='lg:text-lg text-xs rounded-tr-2xl  pl-5 lg:pl-32  text-white capitalize font-sans'>
            Research and Innovation {">"}
          </p>
        </div>
      </div>
      <div className='pb-10 lg:pb-20 lg:px-32 text-lg lg:text-xl w-full '>
        <h1 className='p-2 uppercase mt-5 lg:mt-10 font-bold font-sans text-[#064663]'>
          Research and Innovation
        </h1>
        <div className='flex flex-col lg:flex-row gap-3 mt-10'>
          <img
            src={research}
            alt='Research and Innovation'
            className='h-[15rem] w-full lg:w-[20rem]'
          />
          <div className='px-2'>
            In the face of complex public health challenges, research and
            innovation play a pivotal role in addressing public health
            challenges. <br /> Integrating human, animal, and environmental
            perspectives, research fosters a comprehensive understanding of
            interconnected health dynamics. <br /> <br /> Innovation, driven by
            collaborative efforts, translates research findings into practical
            solutions, enabling effective interventions to mitigate the impact
            of diseases that transcend species boundaries.
            <br />
          </div>
        </div>
      </div>
    </>
  );
};

export default ResearchInnovation;
