import { Menu, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import {
  MenuIcon,
  ClipboardListIcon,
  UsersIcon,
  CubeTransparentIcon,
  BookOpenIcon,
  UserAddIcon,
  ChevronDownIcon,
  ChevronRightIcon,
} from "@heroicons/react/solid";
import { Link, NavLinkProps } from "react-router-dom";

const arr = [
  {
    name: "About Us",
    icon: MenuIcon,
    link: "/about-us",
    children: [
      { title: "Our Story", link: "/our-story", grandchild: [] },
      {
        title: "Our Mission, Vision & Value",
        link: "/about-us",
        grandchild: [],
      },
      { title: "Our Expertise", link: "/about-us", grandchild: [] },
      { title: "Our Team", link: "/our-team", grandchild: [] },
    ],
  },
  {
    name: "Our Work",
    icon: UsersIcon,
    link: "#",
    children: [
      {
        title: "One Health",
        link: "/our-work/one-health",
        icon: MenuIcon,
        grandchild: [
          { title: "Zoonosis", link: "/" },
          { title: "Antimicrobial resistance (AMR)", link: "#" },
          { title: "Environmental health & conservation", link: "#" },
        ],
      },
      {
        title: "Neglected Tropical Disease (NTDs)",
        link: "/our-work/neglected-tropical",
        icon: MenuIcon,
        grandchild: [],
      },
      {
        title: "Water, Sanitation and Hygiene",
        link: "/our-work/water-sanitation",
        icon: MenuIcon,
        grandchild: [],
      },
      {
        title: "Research and Innovation",
        link: "/our-work/research-innovation",
        icon: MenuIcon,
        grandchild: [],
      },
    ],
  },
  {
    name: "Our Impact",
    icon: CubeTransparentIcon,
    link: "#",
  },
  {
    name: "Media",
    icon: BookOpenIcon,
    link: "#",
    children: [
      { title: "Gallery", link: "#", icon: MenuIcon, grandchild: [] },
      { title: "News", link: "#", icon: MenuIcon, grandchild: [] },
      { title: "Newsletter", link: "#", icon: MenuIcon, grandchild: [] },
      { title: "Publications", link: "#", icon: MenuIcon, grandchild: [] },
    ],
  },
  {
    name: "Careers",
    icon: UserAddIcon,
    link: "#",
  },
  {
    name: "Events",
    icon: ClipboardListIcon,
    link: "#",
  },
];

export default function Example() {
  const [openParent, setOpenParent] = useState(null);

  const funcSetParentName = (name) => {
    if (name === openParent) {
      setOpenParent(null);
    } else setOpenParent(name);
  };

  return (
    <div className='w-56 text-right'>
      <Menu as='div' className='relative inline-block text-left'>
        <div>
          <Menu.Button className=' inline-flex justify-center w-full py-2 text-sm font-medium focus:outline-none  focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75'>
            <MenuIcon
              className='w-9 h-9 text-white hover:text-gray-100 hover:text-opacity-80'
              aria-hidden='true'
            />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter='transition ease-out duration-300'
          enterFrom='transform opacity-0 scale-95'
          enterTo='transform opacity-100 scale-100'
          leave='transition ease-in duration-75'
          leaveFrom='transform opacity-100 scale-100'
          leaveTo='transform opacity-0 scale-95'
        >
          <Menu.Items className='absolute right-0 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-[1000]'>
            {arr.map((el, i) => {
              return (
                <div key={el.link} className='px-1 py-1'>
                  {el.children ? (
                    <>
                      <Menu.Items>
                        <div className='p-1'>
                          <Link
                            onClick={() =>
                              el.children
                                ? funcSetParentName(el.name)
                                : setOpenParent(null)
                            }
                            to={`${el.link}`}
                            duration={300}
                            smooth={true}
                            className='text-[#064663] group flex  items-center w-full px-2 py-2 text-sm font-medium hover:bg-[#D7B95A] hover:text-white'
                          >
                            {
                              <el.icon
                                className='w-5 h-5 mr-2 text-[#064663]'
                                aria-hidden='true'
                              />
                            }
                            {el.name}
                            {openParent === el.name ? (
                              <ChevronDownIcon
                                className='ml-2 w-4 h-4 text-[#064663] '
                                aria-hidden='true'
                              />
                            ) : (
                              <ChevronRightIcon
                                className='ml-2 w-4 h-4 text-[#064663] '
                                aria-hidden='true'
                              />
                            )}
                          </Link>
                        </div>
                      </Menu.Items>
                      <Menu.Item>
                        <ul>
                          {el.children &&
                            openParent === el.name &&
                            el.children.map((child, idx) => {
                              return (
                                <li
                                  key={child.link}
                                  className='w-full border-t hover:bg-[#D7B95A]'
                                >
                                  <Link
                                    to={child.link}
                                    className=' text-[#064663] w-full font-medium hover:text-white'
                                    onClick={() => setOpenParent(null)}
                                  >
                                    <p className=' py-2 pl-10 w-full text-xs'>
                                      {child.title}
                                    </p>
                                  </Link>
                                  {child.grandchild &&
                                    child.grandchild.map((child, idx) => {
                                      return (
                                        <ul className='ml-14' key={child.title}>
                                          <li>
                                            <Link
                                              to={child.link}
                                              className=' text-[#064663] text-left text-xs w-full font-medium hover:text-white'
                                              onClick={() =>
                                                setOpenParent(null)
                                              }
                                            >
                                              {child.title}
                                            </Link>
                                          </li>
                                        </ul>
                                      );
                                    })}
                                </li>
                              );
                            })}
                        </ul>
                      </Menu.Item>
                    </>
                  ) : (
                    <Menu.Item>
                      {({ active }) => (
                        <div className='p-1'>
                          <Link
                            onClick={() =>
                              el.children
                                ? funcSetParentName(el.name)
                                : setOpenParent(null)
                            }
                            to={`${el.link}`}
                            duration={300}
                            smooth={true}
                            className={`${
                              active
                                ? "bg-[#D7B95A] text-white"
                                : "text-[#064663]"
                            } group flex items-center font-medium w-full px-2 py-2 text-sm `}
                          >
                            {
                              <el.icon
                                className='w-5 h-5 mr-2 text-[#064663] '
                                aria-hidden='true'
                              />
                            }
                            {el.name}
                          </Link>
                        </div>
                      )}
                    </Menu.Item>
                  )}
                </div>
              );
            })}
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
}
